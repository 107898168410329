import React, { useState } from "react";
import useGlobal from "../store";
import axios from "axios";

export default function Search(props) {
  const [globalState, globalActions] = useGlobal();
  const [name, setName] = useState("");

  const search = function(e) {
    e.preventDefault();
    
    axios({
      url: `/?search=${name}&format=json`,
      method: "GET",
      headers: { "X-CSRF-TOKEN": document.querySelector("[name=csrf-token]").content }
    }).then(function(response){
      globalActions.setPledges(JSON.parse(response.data), false, true);
      globalActions.setSearching(true);
      globalActions.setSearchTerm(name);
      //globalActions.setFormExpanded(true); // we can use this once the form itself shows. 
      { document.body.classList.add('search-entered')}
      { document.getElementById("search-results").scrollIntoView() }
    });
  };

  return(<div className="wall-search">
    <form className="wall-search-form">
    <input className="wall-search-input" type="text" value={name} placeholder="Find a story by name" onChange={(e) => setName(e.target.value)} />
    <button className="wall-search-button" onClick={(e) => search(e)}>Search</button>
  </form></div>);
}
